import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
// import { UserTypes } from '@/_helpers'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: {
			name: 'Order',
		},
	},
	{
		path: '/logginn',
		name: 'Login',
		component: () =>
			import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
	},
	{
		path: '/logout',
		alias: '/loggut',
		name: 'Logout',
		component: () =>
			import(/* webpackChunkName: "Login" */ '../views/Logout.vue'),
	},
	{
		path: '/min-side',
		name: 'MyPage',
		component: () =>
			import(/* webpackChunkName: "MyPage" */ '../views/MyPage.vue'),
	},
	{
		path: '/bestill',
		name: 'Order',
		component: () =>
			import(/* webpackChunkName: "Order" */ '../views/Order.vue'),
		meta: {
			minAccessLevel: 1,
		},
	},
	{
		path: '/ordre/:orderId?',
		name: 'ModifyOrder',
		component: () =>
			import(/* webpackChunkName: "Order" */ '../views/Order.vue'),
		props: true,
		meta: {
			minAccessLevel: 1,
		},
	},
	{
		path: '/kampanjer',
		name: 'Campaigns',
		component: () =>
			import(/* webpackChunkName: "Campaigns" */ '../views/Campaigns.vue'),
		meta: {
			minAccessLevel: 1,
		},
	},
	{
		path: '/kampanjer/:id',
		name: 'Campaign',
		props: true,
		component: () =>
			import(/* webpackChunkName: "Single campaign" */ '../views/Campaign.vue'),
		meta: {
			minAccessLevel: 1,
		},
	},
	{
		path: '/kampanjetyper',
		name: 'Campaigntypes',
		component: () =>
			import(
				/* webpackChunkName: "Campaigntypes" */ '../views/Campaigntypes.vue'
			),
		meta: {
			minAccessLevel: 1,
		},
	},
	{
		path: '/organisasjon',
		name: 'Brand',
		component: () =>
			import(/* webpackChunkName: "Brand" */ '../views/Brand.vue'),
		meta: {
			minAccessLevel: 1,
		},
	},
	{
		path: '/organisasjon/ny',
		name: 'NewBrand',
		component: () =>
			import(/* webpackChunkName: "Brand" */ '../views/Brand.vue'),
		meta: {
			minAccessLevel: 1,
		},
	},
	{
		path: '/firma',
		name: 'Company',
		component: () =>
			import(/* webpackChunkName: "Company" */ '../views/Company.vue'),
		meta: {
			minAccessLevel: 1,
		},
	},
	{
		path: '/firma/ny',
		name: 'NewCompany',
		component: () =>
			import(/* webpackChunkName: "Company" */ '../views/Company.vue'),
		meta: {
			minAccessLevel: 1,
		},
	},
	{
		path: '/ad/:campaignOrderId/:adPlatformType/:adWidth/:adHeight',
		name: 'AdRender',
		component: () =>
			import(/* webpackChunkName: "AdRender" */ '../views/AdRender.vue'),
		props: true,
	},
	{
		path: '/iframead/:adPlatformType/:adWidth/:adHeight',
		name: 'iframead',
		props: true,
	},
	{
		path: '/nytt-passord/:userId/:code',
		name: 'Password',
		component: () =>
			import(/* webpackChunkName: "Password" */ '../views/Password.vue'),
		props: true,
	},
	{
		path: '/registrer/:token',
		name: 'Registrer',
		component: () =>
			import(/* webpackChunkName: "Password" */ '../views/Registrer.vue'),
		props: true,
	},
	{
		path: '/import-eksport',
		name: 'Importexport',
		component: () =>
			import(
				/* webpackChunkName: "Importexport.vue" */ '../views/Importexport.vue'
			),
		meta: {
			minAccessLevel: 1,
		},
	},
	{
		path: '/fakturering',
		name: 'Billing',
		component: () =>
			import(/* webpackChunkName: "Billing.vue" */ '../views/Billing.vue'),
		meta: {
			minAccessLevel: 1,
		},
	},
	{
		path: '/top-secret',
		name: 'Conf',
		component: () =>
			import(/* webpackChunkName: "Conf.vue" */ '../views/Conf.vue'),
		meta: {
			systemAdmin: true,
		},
	},
	{
		path: '/organisasjon/adform',
		name: 'AdformSettings',
		component: () =>
			import(
				/* webpackChunkName: "AdformSettings.vue" */ '../views/AdformSettings.vue'
			),
		props: true,
		meta: {
			systemAdmin: true,
		},
	},
	{
		path: '*',
		redirect: {
			name: 'Order',
		},
	},
]

const router = new VueRouter({
	mode: 'history',
	routes,
})

router.beforeEach((to, from, next) => {
	const { minAccessLevel } = to.meta ? to.meta : { minAccessLevel: null }
	const loginToken = store.state.loginToken

	if (to.meta && to.meta.systemAdmin) {
		if (loginToken.user.systemAdmin) {
			next()
		} else {
			next({ name: 'Order' })
		}
		return
	}
	if (minAccessLevel) {
		if (!loginToken) {
			next({ name: 'Login' })
			return
		}
		if (loginToken.user.userType) {
			store.dispatch('logout')
			return
		}
		if (minAccessLevel > 10) {
			this.$notify('Du har ikke adgang til å besøke denne siden')
			next({ path: '/' })
			return
		}
		if (!loginToken.user.userAccesses.length && !loginToken.user.systemAdmin) {
			next({ name: 'MyPage' })
			return
		}
		store.dispatch('myBrands')
	}
	if (to.name == 'Login' && loginToken) {
		next({ name: 'Order' })
		return
	}

	next()
})

export default router
